const AutoFormat = ({text}) => {
  if (text == null || text === '')
    return text;
  if (typeof text !== "string")
    return text;
  text = text.replace(/&#34;/g, '\"').trim();
  let textAsHtml = "";

  let word = "";
  let wordStart = -1;
  let wordEnd = -1;
  let isLink = false;
  let linkStart = -1;
  let linkEnd = -1;
  let isBoldItalic = false;
  let boldItalicStarCount = 0;
  let starCounter = 0;
  for (const idx in text) { // iterate over string to find words of text (regular words, links or formatted sections)
    const index = Number(idx);
    const char = text[idx];
    switch (char) {
      case " ":
        if (isLink) {
          for (let i = index - 1; i > wordStart; i--) {
            if (!/[.,;:/)*]/.test(text[i])) {
              linkEnd = i + 1;
              break;
            }
          }
        }
        if (!isBoldItalic)
          wordEnd = index;
        break;
      case "h":
        if (wordStart === -1) {
          wordStart = index;
          wordEnd = -1;
        }
        if (text.substring(index, index + 4).startsWith('http')) {
          isLink = true;
          linkStart = index;
          if (isBoldItalic) {
            wordStart -= boldItalicStarCount;
            isBoldItalic = false;
            boldItalicStarCount = 0;
          }
        }
        break;
      default:
        if (wordStart === -1) {
          wordStart = index;
          wordEnd = -1;
        }
    }

    if (char === "*") {
      if (!isLink)
        starCounter++;
    } else {
      if (starCounter > 0) {
        if (!isBoldItalic) {
          isBoldItalic = true;
          boldItalicStarCount = starCounter;
          wordStart = index;
          wordEnd = -1;
        } else {
          wordEnd = index - starCounter;
        }
        starCounter = 0;
      }
    }

    if (wordStart !== -1 && wordEnd !== -1) { // push new formatted word
      word = text.substring(wordStart, wordEnd);
      if (isLink) {
        let beforeLink = text.substring(wordStart, linkStart);
        let link = text.substring(linkStart, linkEnd);
        let afterLink = text.substring(linkEnd, wordEnd);
        textAsHtml = textAsHtml + beforeLink + `<a style={{lineHeight: "60%"}} href="${link}" target="_blank" rel="noreferrer">${link}</a>` + afterLink + " ";
        isLink = false;
        linkStart = -1;
        linkEnd = -1;
      } else if (isBoldItalic) {
        if (boldItalicStarCount === 1)
          textAsHtml = textAsHtml + `<i>${word}</i> `;
        if (boldItalicStarCount === 2)
          textAsHtml = textAsHtml + `<b>${word}</b> `;
        if (boldItalicStarCount >= 3)
          textAsHtml = textAsHtml + `<b><i>${word}</i></b> `;
        isBoldItalic = false;
        boldItalicStarCount = 0;
      } else {
        textAsHtml = textAsHtml + word + " ";
      }
      wordStart = -1;
      wordEnd = -1;
    }
  }

  wordEnd = (starCounter > 0 ? text.length - starCounter : text.length);
  word = wordStart !== -1 ? text.substring(wordStart, wordEnd) : "";
  if (isLink) {
    let beforeLink = text.substring(wordStart, linkStart);
    let link = text.substring(linkStart, wordEnd);
    textAsHtml = textAsHtml + beforeLink + `<a style={{lineHeight: "60%"}} href="${link}" target="_blank" rel="noreferrer">${link}</a> `;
  } else if (isBoldItalic) {
    if (boldItalicStarCount === 1)
      textAsHtml = textAsHtml + `<i>${word}</i> `;
    if (boldItalicStarCount === 2)
      textAsHtml = textAsHtml + `<b>${word}</b> `;
    if (boldItalicStarCount >= 3)
      textAsHtml = textAsHtml + `<b><i>${word}</i></b> `;
  } else {
    textAsHtml = textAsHtml + word + " ";
  }
  return <span key={"*"} dangerouslySetInnerHTML={{__html: textAsHtml}}/>
}
export default AutoFormat;