import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PageContainer from "../../layout/PageContainer";
import {editMyTransport, getMyVehicle} from "../../dao";
import {handleErrorLoading, handleErrorSubmit, parseTime, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faPencil} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Table from "react-bootstrap/Table";
import PageHeading from "../../layout/PageHeading";
import FormField from "../../components/form/FormField";
import AutoFormat from "../../components/visual/AutoFormat";
import {labels} from "../../themeLabels";
import VehicleList from "../../components/parts/VehicleList";
import VehicleIcon from "../../components/visual/VehicleIcon";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";


const EventMyTransport = () => {
  const { eventId} = useParams();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState({});
  const [changeCrew, setChangeCrew] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState("");

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [eventId]);

  function loadData() {
    getMyVehicle(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response, "Chybí přihláška na akci..."));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setVehicleInfo(response?.info);
        setLoading(false);
        setProcessing(false);
      })
      .catch((err) => console.log(err));
  }

  function editInfo() {
    editMyTransport(data.id, 'info', vehicleInfo)
      .then((res) => {
        if (res.ok) {
          setSuccess("Upraveno");
          loadData();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se upravit"));
        }
      })
    setChangeInfo(false);
  }

  function editCrew(vehicleId, personId) {
    setProcessing(true);
    editMyTransport(vehicleId, 'crew', personId)
      .then((res) => {
        if (res.ok) {
          setSuccess("Upraveno");
          loadData();
        } else {
          setError(handleErrorSubmit(res, "Nezdařilo se upravit"));
        }
      })
    setChangeCrew(false);
  }

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Moje doprava | ' + data?.event?.title)
  return (
    <PageContainer>
      <PageHeading heading={"Moje doprava: " + data?.event?.title} id={eventId} secondLabel={"Klubová doprava"} secondLink={"/akce/"+eventId+"/doprava/detail"}/>
      {
        (labels.MyTransportSelf && !data?.id) ? <Row>
          <VehicleList eventId={eventId} vehicles={data?.vehicles} user={data?.logged} editCrew={editCrew} processing={processing}
                       assignable={data?.assignable} removable={data?.removable}/>
          </Row> : <>
            <div style={{textAlign: "center"}}>
              <Row><Col className="align-content-center"><VehicleDetail/></Col></Row>
            </div>
            <ChangeCrew/>
          </>
      }
      <Modal show={changeInfo} onHide={() => setChangeInfo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upravit info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormField label={"Info"} value={vehicleInfo} setValue={setVehicleInfo} valueLimit={256} controlId={"info"} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setChangeInfo(false)}>Zavřít</Button>
          <Button variant="primary" onClick={editInfo}>Potvrdit</Button>
        </Modal.Footer>
      </Modal>
      <LoadingOverlay loading={processing}/>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  )

  function VehicleDetail(){
    const Person = ({person}) => {
      if (person == null)
        return;
      return (
        <ListGroup.Item>
          <Row className="align-items-baseline">
            <Col xs={5}>{person?.full_name}</Col>
            <Col xs="auto" style={{whiteSpace: "nowrap"}}>{person?.phone}</Col>
            {person?.start_time != null && <Col className="text-end"><Badge style={{fontSize: "12px"}} bg="primary">{parseTime(person?.start_time)}</Badge></Col>}
          </Row>
        </ListGroup.Item>
      )
    }

    const freeSpaces = data?.crew !== undefined ? data?.capacity - data?.crew.length : 0;

    return (
      <Card className="mb-2" style={{margin: "auto", marginTop: "0px", maxWidth: "600px"}}>
        <Card.Body>
          <Card.Title><VehicleIcon title={data?.title} />  {data?.title}</Card.Title>
          <div style={{textAlign: "left"}}>
            <div><FontAwesomeIcon icon={faCircleInfo} color="gray" /> <AutoFormat text={data?.info} /> {data?.is_driving && <Button className="mb-0" variant="link"><FontAwesomeIcon icon={faPencil} onClick={() => {
              setChangeInfo(true)
            }}/></Button>}</div>
            <ListGroup>
              <Person person={data?.driver}/>
            </ListGroup>
            <hr/>
            <ListGroup className="mb-3">
              <Crew/>
            </ListGroup>
            {freeSpaces > 0 &&
              <Row>
                <Col>
                  <p>Volná místa: {freeSpaces}</p>
                </Col>
                {(!data?.transport_handled && data?.is_driving) &&
                  <Col className="text-end">
                    <Button onClick={() => setChangeCrew(true)}>Upravit si posádku</Button>
                  </Col>
                }
              </Row>
            }
            {(!data?.transport_handled && labels.MyTransportSelf && data.driver?.id !== data?.logged?.id) &&
              <Button disabled={processing} onClick={() => editCrew(data.id, data?.logged.id)}>Odebrat se</Button>}
          </div>
        </Card.Body>
      </Card>
    )

    function Crew() {
      if (data?.crew === undefined)
        return;
      const crew = [];
      data?.crew.forEach(person => {
        if (person.full_name != null){
          crew.push(
            <Person person={person} key={person.id} />
          );
        }else{
          crew.push(<Card className="mt-2" style={{borderStyle: "dotted"}}>
            <i className="m-1">Volno</i>
          </Card>);
        }
      })
      return crew;
    }
  }

  function ChangeCrew() {
    return (
      <Modal show={changeCrew} onHide={()=>setChangeCrew(false)}
        size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upravit posádku
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeopleTable data={data?.crew} crewMode={true}/>
          <hr/>
          <PeopleTable data={data?.unassigned} crewMode={false}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setChangeCrew(false)}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    )

    function PeopleTable(props) {
      const rows = [];
      for(let i in props.data){
        const person = props.data[i];
        rows.push(
          <tr key={person.id}>
            <td>{person.reg_number}</td>
            <td>{person.full_name}</td>
            <td><Button onClick={() => editCrew(data.id, person.id)}>{props.crewMode ? "Odebrat" : "Přidat"}</Button></td>
          </tr>
        );
      }
      return(
        <Table borderless>
          <tbody>
          {rows}
          </tbody>
        </Table>
      )
    }
  }
}

export default EventMyTransport;