import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import React from "react";


const LoadingOverlay = ({loading, text="Zpracování"}) => {
  return <Modal show={loading}>
    <Modal.Header>
      <FontAwesomeIcon icon={faRotate} size='3x' spin/> &nbsp;
      <Modal.Title>{text}</Modal.Title>
    </Modal.Header>
  </Modal>
}

export default LoadingOverlay;