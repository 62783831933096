import PageContainer from "../../layout/PageContainer";
import {handleErrorLoading, handleErrorSubmit, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import {deleteBugReport, getBugReports, putBugReport, putSolveBugReport} from "../../dao";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxArchive, faBoxOpen, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import AutoFormat from "../../components/visual/AutoFormat";
import FormCheckbox from "../../components/form/FormCheckbox";
import Form from "react-bootstrap/Form";


const BugsReported = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [archived, setArchived] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [toSolve, setToSolve] = useState(null);
  const [solution, setSolution] = useState("");
  const [sendEmail, setSendEmail] = useState(false);

  useEffect(() => {
    loadData();
  }, [archived]);

  function loadData() {
    getBugReports(archived)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
      })
      .catch((err) => console.log(err));
  }

  function deleteReport(id) {
    deleteBugReport(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno / Archivováno");
          loadData();
        } else {
          setError(handleErrorSubmit(response));
        }
        setToDelete(null);
      })
  }

  function solveReport(id) {
    putSolveBugReport(id, solution, sendEmail)
      .then((response) => {
        if (response.ok) {
          setSuccess("Odpověď uložena");
          loadData();
        } else {
          setError(handleErrorSubmit(response));
        }
        setToSolve(null);
      })
  }

  function unarchiveReport(id) {
    putBugReport(id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Archivovace zrušena");
          loadData();
        } else {
          setError(handleErrorSubmit(response));
        }
        setToDelete(null);
      })
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Nahlášené chyby');
  return (
    <PageContainer>
      <h1>Nahlášené chyby</h1>
      <Row className="mb-2">
        <Col>
          <FormCheckbox type={"switch"} label={"Archivované"} value={archived} setValue={setArchived}/>
        </Col>
      </Row>
      <ReportsSection />
      <Modal show={toDelete != null} onHide={()=>setToDelete(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>{toDelete?.archived ? "Smazat" : "Archivovat"}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{toDelete != null && <>{toDelete.title}</>}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setToDelete(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>deleteReport(toDelete.id)}>
            {toDelete?.archived ? "Smazat" : "Archivovat"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={toSolve != null} onHide={()=>setToSolve(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{toSolve?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AutoFormat text={toSolve?.detail}/>
          <hr/>
          <Form.Group className="mb-3" controlId="solution">
            <Form.Label column={false}>Odpověď:</Form.Label>
            <Form.Control type="text" as="textarea" rows={10} value={solution} onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 1500) {
                setSolution(inputValue);
              }
            }} />
          </Form.Group>
          <FormCheckbox label={"Odeslat email o vyřešení"} value={sendEmail} setValue={setSendEmail} controlId={"sendEmail"}
                        tooltip={"Autor/ka hlášení obdrží email se zněním odpovědi"} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setToSolve(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={()=>solveReport(toSolve.id)}>
            Uložit
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> setSuccess("")}/>
    </PageContainer>
  )

  function ReportsSection() {
    const rows = [];
    data.forEach((record)=>{
      rows.push(
        <div key={record.id}>
          <Row><Col>{record?.archived && <><FontAwesomeIcon icon={faBoxArchive}/> </>}<b>{record.title}</b></Col></Row>
          <Row className="mb-2"><Col><AutoFormat text={record.detail}/></Col></Row>
          <Row>
            <Col><i>{record.reporter.reg_number} – {record.reporter.full_name}</i></Col>
          </Row>
          <Row>
            <Col>{parseDateTime(record.timestamp)} {record?.archived && <><FontAwesomeIcon className="pointing" icon={faBoxOpen} onClick={() => unarchiveReport(record.id)}/> </>}<FontAwesomeIcon className="pointing" icon={record?.archived ? faTrash : faBoxArchive} onClick={() => setToDelete(record)}/></Col>
            <Col className="text-end">
              <Row>
                <Col>
                  <Button variant={record?.solution ? "outline-secondary" : "secondary"} onClick={() => {
                    setToSolve(record);
                    setSolution(record.solution);
                    setSendEmail(record.solution === "")
                  }}>Odpověď</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              {record?.solution && <>
                <Row className="mb-2 mt-2"><i>{record.solution}</i></Row>
                <Row><i>{record.solver.reg_number} – {record.solver.full_name}</i></Row>
              </>}
            </Col>
          </Row>
          <hr/>
        </div>
      );
    });
    return rows;
  }
}

export default BugsReported;