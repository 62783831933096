import Form from "react-bootstrap/Form";
import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";


const FormFieldCategory = ({classData, setEditClasses, disabled=false, inline=false, className="mb-3", controlId}) => {
  let nameString = ""
  for (const i in classData) {
    const cls = classData[i];
    if (cls.title !== "")
      nameString = nameString + cls.title + "; "
  }
  if (inline) {
    return (
      <Form.Group className={className} controlId={controlId}>
        <div style={{display: "flex"}}>
          <Form.Label column={true} className="me-2">Kategorie: </Form.Label>
          <InputGroup>
            <Form.Control style={{fontSize: "0.9rem"}} value={nameString} disabled={disabled} readOnly={true} />
            <Button style={{ width: '30%' }} disabled={disabled} onClick={() => setEditClasses(true)}>Editovat</Button>
          </InputGroup>
        </div>
      </Form.Group>
    )
  }

  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Label column={false}>Kategorie: </Form.Label>
      <InputGroup>
        <Form.Control style={{fontSize: "0.9rem"}} value={nameString} disabled={disabled} readOnly={true} />
        <Button style={{ width: '30%' }} disabled={disabled} onClick={() => setEditClasses(true)}>Editovat</Button>
      </InputGroup>
    </Form.Group>
  )
}

export default FormFieldCategory;