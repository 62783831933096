const themeConfig = {
  TAP: {
    Theme: 'TAP',
    ThemeColorPrimary: '#198754',
    ThemeColorSecondary: '#c16d18',
    ApiUrl: 'https://api.tapis.tretra.org/',
    SystemStartYear: 2024,
    SystemShortName: 'Tapis',
    SystemDescription: 'Tretrácký informační systém',
    AdminEmail: 'tapis@tretra.org',
    HomeClub: "TAP",
    HomeClubName: "KOB Tretra Praha",
    HomeClubBankAccount: "2802097915/2010",
    TabTitle: 'Tapis – Tretrácký informační systém',
    MetaDescription: 'Informační systém KOB Tretra Praha',

    homeSection: 'Domů',
    clubSection: 'Klub',
    cupsSection: 'Žebříčky',
    myRacesSection: 'Mé závody',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: 'Přihlásit se',
    entryPagePreliminaryCreate: 'Přihlásit se',
    entryPageEdit: 'Upravit přihlášku',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: 'Přihlásit ostatní',

    servicesPage: 'Služby',
    offersPage: 'Nabídky',
    transportHandleSwitch: 'Zveřejnit uživatelům',
    transportHandleTooltip: 'Pokud není rozdělení zveřejněno, pak ho běžní uživatelé nevidí',

    clubRankingPage: 'Klubový ranking',

    ClubPageTableLinks: '1',
    ClubAdminPageTableLinks: '1',
    ClubAdminSpecialSyncToOris: '1',
    MyTransportSelf: '',
    EventDining: '',
    EventAccommodation: '1',
    EventApplyToOris: '1',
    EventDNSExcuse: '1',
    UserBilling: '1',

    usernamePlaceholder: 'Tretrak',
  },
  PGP: {
    Theme: 'PGP',
    ThemeColorPrimary: '#2C438B',
    ThemeColorSecondary: '#F04E37',
    ApiUrl: 'https://api.pragis.sk-praga.cz/',
    SystemStartYear: 2024,
    SystemShortName: 'PragIS',
    SystemDescription: 'Pragovácký informační systém',
    AdminEmail: 'syon@centrum.cz',
    HomeClub: "PGP",
    HomeClubName: "SK Praga",
    HomeClubBankAccount: "???",
    TabTitle: 'PragIS – Pragovácký informační systém',
    MetaDescription: 'Přihlášení do PragISu',

    homeSection: 'Kalendář akcí',
    clubSection: 'Seznam členů',
    cupsSection: '',
    myRacesSection: '',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: '',
    entryPagePreliminaryCreate: '',
    entryPageEdit: 'Moje přihláška',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: '',

    servicesPage: '',
    offersPage: 'Nabídky',
    transportHandleSwitch: 'Uzamknout úpravy',
    transportHandleTooltip: 'Uživatelé si již nebudou moci sami měnit složení aut',

    clubRankingPage: 'Klubový ranking',

    ClubPageTableLinks: '',
    ClubAdminPageTableLinks: '',
    ClubAdminSpecialSyncToOris: '',
    MyTransportSelf: '1',
    EventDining: '',
    EventAccommodation: '',
    EventApplyToOris: '',
    EventDNSExcuse: '',
    UserBilling: '',

    usernamePlaceholder: 'PGP0000',
  },
};

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts[0]; // Třetí řád domény
  }
  return null;
};

const getTheme = () => {
  switch (getSubdomain()) {
    case 'tapis':
      return 'TAP'
    case 'pragis':
      return 'PGP'
    default:
      return 'TAP'
  }
}

const themeVariant = getTheme();
const currentTheme = themeConfig[themeVariant].Theme;
const ApiUrl = (process.env.NODE_ENV === 'production' ? themeConfig[themeVariant].ApiUrl : 'http://localhost:8000/');
export {currentTheme, ApiUrl};
export const labels = themeConfig[themeVariant];
