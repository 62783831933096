import { Link } from "react-router-dom";
import { fa1, fa2, fa3, faK, faP, faS, faV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {parseDateTime, parseDateWithoutYear} from "../../helpers/Functions";
import React from "react";
import {HomeClub, ORIS_URL} from "../../helpers/Constants";

const TermIndicator = ({event, vertical=false}) => {
  const TermDiv = ({icon, date=null}) => {
    if (vertical) {
      return <div className="align-center" style={{fontSize: "0.8rem"}}>
        {icon} {date && <p className="mb-0"><i style={{fontSize: "0.6rem", textAlign: "justify"}}>{date}</i></p>}
      </div>
    }
    return <div>
      {icon} <i style={{fontSize: "0.7rem", textAlign: "justify"}}>{date}</i>
    </div>
  }

  switch (event.term) {
    case 0:
      return <TermDiv icon={<FontAwesomeIcon icon={faP} style={{color: "DeepSkyBlue"}} title={parseDateTime(event.term_preliminary)}/>}
                      date={parseDateWithoutYear(event.term_preliminary)} />;
    case 1:
      let termFirst = (event.term_manual != null ? event.term_manual : event.term_first);
      const termIcon = (event.term_manual != null ? faK : fa1);
      return <TermDiv icon={<FontAwesomeIcon icon={termIcon} style={{color: "LimeGreen"}} title={parseDateTime(termFirst)}/>}
                      date={parseDateWithoutYear(termFirst)} />;
    case 2:
      return <TermDiv icon={<FontAwesomeIcon icon={fa2} style={{color: "Orange"}} title={parseDateTime(event.term_second)}/>}
                      date={parseDateWithoutYear(event.term_second)} />;
    case 3:
      return <TermDiv icon={<FontAwesomeIcon icon={fa3} style={{color: "Crimson"}} title={parseDateTime(event.term_third)}/>}
                      date={parseDateWithoutYear(event.term_third)} />;
    default:
      if (event.results && event.club_entries > 0) {
        const resultsUrl = ORIS_URL + (event?.is_relay ? "VysledkyStafet?id=" : "Vysledky?id=") + event.oris_id + "&club=" + HomeClub;
        return <TermDiv icon={<Link to={resultsUrl}><FontAwesomeIcon icon={faV} style={{color: "DarkViolet"}} href={resultsUrl}/></Link>} />;
      }
      if (event.startlists && event.club_entries > 0)
        return <TermDiv icon={<Link to={"/akce/" + event.id + "/klub"}><FontAwesomeIcon icon={faS} style={{color: "DeepPink"}}/></Link>} />;
  }
}

export default TermIndicator;