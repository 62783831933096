import React, {useEffect, useState} from "react";
import {getMyBugReports} from "../../dao";
import {handleErrorLoading, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AutoFormat from "../../components/visual/AutoFormat";
import WarningCard from "../../components/overlays/WarningCard";
import Card from "react-bootstrap/Card";


const MyBugReports = () => {
  const [data, setData] = useState({});
  const [errorRemote, setErrorRemote] = useState("");

  useEffect(() => {
    getMyBugReports()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {records: []};
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
      })
      .catch((err) => console.log(err));
  }, []);

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Moje nahlášení');
  return (
    <PageContainer>
      <h1>Moje nahlášení</h1>
      <br/>
      <ReportsSection />
    </PageContainer>
  )

  function ReportsSection() {
    const rows = [];
    for (const i in data.records) {
      const record = data.records[i];
      rows.push(
        <div key={record.id}>
          <Row className="mb-1">
            <Col xs={9}>
              <Card>
                <Card.Body>
                  <Row className="mb-1">
                    <Col><b>{record.title}</b></Col>
                  </Row>
                  <Row className="mb-2">
                    <Col><AutoFormat text={record.detail}/></Col>
                  </Row>
                  <Row>
                    <Col>{parseDateTime(record.timestamp)}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={3}/>
          </Row>
          {record?.solution ? <Row>
            <Col xs={3}/>
            <Col xs={9}>
              <Card>
                <Card.Body style={{textAlign: "right"}}>
                  <Row className="mb-1">
                    <Col><b>Odpověď na "{record.title}"</b></Col>
                  </Row>
                  <Row className="mb-2">
                    <Col><AutoFormat text={record.solution}/></Col>
                  </Row>
                  <Row><i>{record.solver.reg_number} – {record.solver.full_name}</i></Row>
                </Card.Body>
              </Card>
            </Col>
          </Row> : <Row>
            <Col className="text-end">
              <i>Odpověď zatím nepřidána...</i>
            </Col>
          </Row>}
          <hr/>
        </div>
      );
    }

    if (rows.length === 0)
      return <WarningCard text={"Žádná nahlášení"}/>
    return rows;
  }
}

export default MyBugReports;