import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';

const WarningCard = ({text, color="inherit", marginTop="15%"}) => {
  if (text == null || text === "")
    text = "Není k dispozici";
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: marginTop
    }}>
      <Card>
        <Card.Body style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <FontAwesomeIcon icon={faTriangleExclamation} size='3x' color={color}/>
          <h3 style={{marginLeft: "5px"}}>{text}</h3>
        </Card.Body>
      </Card>
    </div>
  )
};

export default WarningCard;