import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useParams} from "react-router-dom";
import {deleteEnroll, sendEnroll} from "../../dao";
import React, { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {faLightbulb, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {classDict, getClassName, handleErrorSubmit, preselectClass, setBrowserTabText, siCardDict} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import {PunchingOptions} from "../../helpers/Constants";
import Loader from "../../components/overlays/Loader";
import FormField from "../../components/form/FormField";
import EventDate from "../../components/parts/EventDate";
import PageHeading from "../../layout/PageHeading";
import Modal from "react-bootstrap/Modal";
import FormSelectDict from "../../components/form/FormSelectDict";
import CampEnrollFormSection from "../../components/parts/CampEnrollFormSection";
import OfferOrderSection from "../../components/parts/OfferOrderSection";
import FormEnrollTransport from "../../components/form/FormEnrollTransport";
import {labels} from "../../themeLabels";

const EnrollCamp = (props) => {
  const { eventId } = useParams();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState({});
  const [form, setForm] = useState([]);
  const navigate = useNavigate();

  const [si, setSi] = useState("");
  const [siNumber, setSiNumber] = useState("");
  const [transport, setTransport] = useState(0);
  const [carCapacity, setCarCapacity] = useState(null);
  const [carInfo, setCarInfo] = useState("");
  const [classId, setClassId] = useState("");
  const [note, setNote] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    setData(props.data);
    setForm(props.data.detail.form);
    setSi(props.si);
    setSiNumber(props.siNumber);
    setTransport(props.transport);
    setCarCapacity(props.carCapacity);
    setNote(props.note);
    if (props.data?.event?.discipline === "SO")
      setClassId(preselectClass(props.data?.user?.classes))
    if (props.data.applied)
      setClassId(props.data.detail.submission.class_name)
    setLoading(false);
  }, [setData, data, setForm, setLoading, setSi, setSiNumber, setNote]);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if(!checkRequiredFields()){
      setError("Nevyplněna všechna povinná pole");
      return;
    }
    var rentSi = si === 'rent';
    var o_si;
    if(si === 'rent'){
      o_si = 0;
    }else if(si === 'custom'){
      o_si = siNumber;
    }else{
      o_si = si;
    }
    let mode = data.applied ? "PUT" : "POST";
    const formData = {
      "person" : data.user.id,
      "class_name" : getClassName(classId, data.user.classes),
      "form": form,
      "si" : o_si,
      "rent_si" : rentSi,
      "car_capacity" : carCapacity,
      "car_info": carInfo,
      "transport" : transport,
      "note" : note,
    }

    sendEnroll(eventId, data.user.id, formData, mode)
      .then((response) => {
        if (response.ok)
          (mode === "POST") ? setSuccess("Přihláška přidána") : setSuccess("Přihláška upravena");
        else
          setError(handleErrorSubmit(response, (mode === 'POST' ? "Nepodařilo se přihlásit" : "Nepodařilo se upravit přihlášku")));
      })
  };

  function checkRequiredFields(){
    let result = true;
    form.forEach((field)=> {
      let invalid = false;
      if (field.required) {
        if (!field.closed_ended) {
          if (isEmpty(field.value)) {
            invalid = true;
          }
        } else {
          let isAnythingSelected = false;
          if (field.multichoice) // multiselect
            field.choices.forEach((choice) => {isAnythingSelected = (choice.checked ? true : isAnythingSelected)});
          else // single-select
            field.choices.forEach((choice) => {isAnythingSelected = (choice.id === field.selected ? true : isAnythingSelected)});
          if (field?.selected === 0 && !isEmpty(field?.value))
            isAnythingSelected = true;
          if (!isAnythingSelected) {
            invalid = true;
          }
        }
      }
      field.invalid = invalid;
      if (invalid)
        result = false;
    });
    return result;
  }

  function isEmpty(item){
    return typeof item === "undefined" || item == null || item === "";
  }

  function redirectToUserList(value){
    if (value==="*") {
      navigate("/akce/"+eventId,{state:{showEnrollList:true}});
    }
  }
  const CreatorEditorText = ({person, label}) => {
    const labelText = label + ": ";
    if (person == null)
      return "";
    return <i>{labelText} {person.reg_number} – {person.full_name}</i>;
  }

  if (loading)
    return <Loader />;

  const hasClasses = (data?.event?.discipline === "SO" && Object.keys(classDict(data?.user?.classes, data?.event?.type)).length > 0);

  setBrowserTabText('Přihláška | ' + data?.event?.title)
  return (
    <PageContainer background={getBackground()}>
      <PageHeading heading={"Přihláška: " + data?.event?.title} id={eventId} />
      <Row>
        <Col><p className="mb-2"><EventDate event={data?.event} /></p></Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md>
            <FormSelectDict label={"Uživatel"} value={"-"} setValue={redirectToUserList} disabled={data?.event?.term < 0} controlId={"user"}
                            options={{'-' : data?.user?.full_name + " - " + data?.user?.reg_number, '*': "Přihlásit ostatní"}} />
          </Col>
          {hasClasses && <Col md={4}>
            <FormSelectDict label={"Kategorie"} value={classId} setValue={setClassId} options={classDict(data?.user?.classes, data?.event?.type)} controlId={"class"} />
          </Col>}
          <Col md>
            <FormSelectDict label={<Row className="mb-2"><Col xs="auto">Čip:</Col><Col className="text-end">Ražení: {PunchingOptions[data?.event?.punching]}</Col></Row>}
                            options={siCardDict(data?.user?.si_cards)} value={si} setValue={setSi} disabled={data.event.term < 0}
                            controlId={"si"} />
            {si === "custom" && (<>
              <FormField label={"Číslo neuloženého čipu"} value={siNumber} setValue={setSiNumber} type={"number"} isInvalid={siNumber === ""}
                         disabled={data.event.term < 0} controlId={"siUnsavedNumber"} />
              <i><FontAwesomeIcon icon={faLightbulb} color="gold"/> Své čipy si můžeš uložit v nastavení účtu</i>
            </>)
            }
          </Col>
          {((labels.MyTransportSelf && data?.event?.transport >= 0) || data?.event?.transport === 0) ? <Col xs={12}>
            <FormEnrollTransport data={data} transport={transport} setTransport={setTransport} carCapacity={carCapacity} setCarCapacity={setCarCapacity}
                                 carInfo={carInfo} setCarInfo={setCarInfo} />
          </Col> : <Col lg>
            <FormEnrollTransport data={data} transport={transport} setTransport={setTransport} carCapacity={carCapacity} setCarCapacity={setCarCapacity}
                                 carInfo={carInfo} setCarInfo={setCarInfo} />
          </Col>}
        </Row>
        <hr/>
        <h2>Formulář pro účastníky</h2>
        <p className="mb-4"><i>Pole s hvězdičkou jsou povinná</i></p>
        <div>
          <CampEnrollFormSection form={form} setForm={setForm} note={note} setNote={setNote}/>
        </div>
        <Row>
          {data?.applied &&
            <Col md className="mb-2">
              <CreatorEditorText person={data.detail.submission.created_by} label={"Vytvořil/a"}/><br/>
              <CreatorEditorText person={data.detail.submission.edited_by} label={"Upravil/a"}/>
            </Col>
          }
          <Col className="text-end">
            <EnrollDelete/>
            <EnrollSubmit/>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <OfferOrderSection eventId={eventId} offers={data.offers} user={data.user} />
        </Col>
      </Row>
      <br/>
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně smazat přihlášku?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>Zavřít</Button>
          <Button variant="primary" onClick={tryDeleteEnroll}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => {
        setSuccess("");
        window.location.reload();
      }}/>
    </PageContainer>
  );

  function EnrollSubmit(){
    if(data?.applied)
      return <Button style={{"marginLeft": "10px"}} variant="primary" type="submit">Upravit přihlášku</Button>;
    return <Button variant="primary" type="submit" disabled={(transport > 1 && carCapacity == null)} style={{fontSize: "1.1em"}}><b>Přihlásit</b></Button>;
  }

  function EnrollDelete(){
    if(data?.applied && data.event.term > 0){
      return(<Button variant="secondary" onClick={() => setConfirmDelete(true)}>Smazat přihlášku</Button>);
    }
  }
  function tryDeleteEnroll(){
    deleteEnroll(eventId, userId)
      .then((response)=>{
        if (response.ok) {
          setSuccess("Přihláška smazána");
        } else {
          setError(handleErrorSubmit(response, "Nepodařilo se smazat"));
        }
        setConfirmDelete(false);
      })
  }

  function getBackground(){
    if(data?.applied)
      return "rgba(2,200,30,0.2)";
    return "rgba(0,0,0,0)";
  }
};

export default EnrollCamp;