import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {textBreakLine} from "../../helpers/Functions";


const FormCheckbox = ({label, value, setValue, type="checkbox", disabled=false, tooltip="", condensed=false, className="", controlId}) => {
  const [showHelp, setShowHelp] = useState(false);

  const HelpModal = () => {
    return <Modal show={showHelp} onHide={() => setShowHelp(false)}>
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faQuestionCircle} />&nbsp;{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {textBreakLine(tooltip)}
      </Modal.Body>
    </Modal>
  }

  const FieldLabel = () => {
    if (typeof label !== "string")
      return label;
    return <>{label} {tooltip !== "" && <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setShowHelp(true)} />}</>;
  }

  return (
    <Form.Group className={condensed ? "mb-0" : "mb-3"} controlId={controlId}>
      <Form.Check type={type} label={<FieldLabel/>} defaultChecked={value} disabled={disabled} className={className}
                  onChange={(e) => setValue(e.target.checked)}/>
      <HelpModal />
    </Form.Group>
  )
}

export default FormCheckbox;