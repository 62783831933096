import Form from "react-bootstrap/Form";
import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";


const FormFieldPassword = ({label, value, setValue, showPassword, setShowPassword, disabled=false, readOnly=false, inline=false, isInvalid=false, isValid=false, placeholder="", autoComplete="new-password", className="mb-3", controlId}) => {
  const ControlField = () => {
    return (<Form.Control style={{fontSize: "0.9rem"}} type={showPassword ? 'text' : 'password'} value={value} disabled={disabled} readOnly={readOnly}
                          isInvalid={isInvalid} isValid={isValid} placeholder={placeholder} autoComplete={autoComplete}
                          onChange={(e) => setValue(e.target.value)} />
    )
  }

  if (inline) {
    return (
      <Form.Group className={className} controlId={controlId}>
        <div style={{display: "flex"}}>
          {label !== "" && <Form.Label column={true} className="me-2">{label}: </Form.Label>}
          <InputGroup>
            {ControlField()}
            <InputGroup.Text style={{width: "45px", alignItems: "center"}}>
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} onClick={() => {
                setShowPassword(!showPassword)
              }}/>
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Form.Group>
    )
  }

  return (
    <Form.Group className={className} controlId={controlId}>
      {label !== "" && <Form.Label column={false}>{label}: </Form.Label>}
      <InputGroup>
        {ControlField()}
        <InputGroup.Text style={{width: "45px", alignItems: "center"}}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} onClick={() => {
            setShowPassword(!showPassword)
          }}/>
        </InputGroup.Text>
      </InputGroup>
    </Form.Group>
  )
}

export default FormFieldPassword;