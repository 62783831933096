import Form from "react-bootstrap/Form";
import React from "react";
import {SelectOptionsArray} from "./SelectOptions";


const FormSelectArray = ({label="", value, setValue, options, disabled=false, inline=false, className="mb-3", size="", help=undefined, controlId}) => {
  if (inline) {
    return (
      <Form.Group className={className} controlId={controlId}>
        <div style={{display: "flex"}}>
          {label !== "" && <Form.Label column={true} className="me-2">{label}: {help}</Form.Label>}
          <Form.Select style={{fontSize: "0.9rem"}} value={value} disabled={disabled} size={size} onChange={(e) => setValue(e.target.value)}>
            <SelectOptionsArray options={options}/>
          </Form.Select>
        </div>
      </Form.Group>
    )
  }

  return (
    <Form.Group className={className} controlId={controlId}>
      {label !== "" && <Form.Label column={false}>{label}: {help}</Form.Label>}
      <Form.Select style={{fontSize: "0.9rem"}} value={value} disabled={disabled} size={size} onChange={(e) => setValue(e.target.value)}>
        <SelectOptionsArray options={options}/>
      </Form.Select>
    </Form.Group>
  )
}

export default FormSelectArray;