import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {SelectOptionsDict} from "./SelectOptions";
import {InterestExtendedOptions, InterestSemiExtendedOptions} from "../../helpers/Constants";
import {labels} from "../../themeLabels";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import FormField from "./FormField";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Modal from "react-bootstrap/Modal";


const FormEnrollTransport = ({data, transport, setTransport, carCapacity, setCarCapacity, carInfo, setCarInfo}) => {
  const [showHelp, setShowHelp] = useState(false);
  const navigate = useNavigate();
  const transportSaved = data?.event?.type === 2 ? data?.detail?.submission?.transport : data?.detail?.transport;

  return (
    <Row className="align-items-center">
      <Col>
        <Form.Group className="mb-3" controlId="transport">
          {transport > 1 ? <Row className="mb-2">
              <Col xs="auto">Společná doprava:</Col>
              <Col className="text-end">Počet míst <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setShowHelp(true)} />:</Col></Row> :
          <Form.Label column={false}>Společná doprava:</Form.Label>}
          <InputGroup>
            <Form.Select style={{ width: '70%', fontSize: "0.9rem"}}
                         disabled={!(data?.event?.transport === 0 || (labels.MyTransportSelf && data?.event?.transport >= 0)) || (data?.applied && !data.detail?.edit_transport)}
                         value={transport} onChange={(e) => setTransport(Number(e.target.value))}>
              <SelectOptionsDict options={data?.event?.transport > 0 ? InterestSemiExtendedOptions : InterestExtendedOptions} />
            </Form.Select>
            {(transportSaved === 1 && transport === 1 && data?.applied && labels.MyTransportSelf) &&
              <Button style={{ width: '30%' }} disabled={transportSaved !== transport} onClick={() => navigate(`/akce/${data?.event?.id}/doprava`)}>Výběr auta</Button>}
            {transport > 1 &&
              <Form.Control
                type="number" style={{ maxWidth: '30%', fontSize: "0.9rem" }} isInvalid={carCapacity == null}
                disabled={data?.event?.transport !== 0 || (data?.applied && !data.detail?.edit_transport)}
                value={carCapacity} onChange={(e) => {
                setCarCapacity(e.target.value === "" ? null : Math.min(9, Math.max(1, Number(e.target.value) % 10)))
              }}/>
            }
          </InputGroup>
        </Form.Group>
        {data?.event?.transport_info !== "" && <p><FontAwesomeIcon icon={faInfoCircle} color="gray"/> <i>{data?.event?.transport_info}</i></p>}
      </Col>
      <Col md={5}>
        {(transport > 1 && !data.applied) ?
          <FormField label={<Row className="mb-2"><Col xs="auto">Mé info k autu:</Col><Col className="text-end">Dopravu
            hledá: <b>{data?.event?.transport_requests}</b> lidí</Col></Row>}
                     value={carInfo} setValue={setCarInfo} valueLimit={128} controlId={"carInfo"}/> :
          <p style={{textAlign: "center"}}>Dopravu hledá: <b>{data?.event?.transport_requests}</b> lidí</p>

        }
      </Col>
      <Modal show={showHelp} onHide={() => setShowHelp(false)}>
        <Modal.Header closeButton>
          <Modal.Title><FontAwesomeIcon icon={faQuestionCircle} />&nbsp; Počet míst</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Počet volných míst v autě (tj. nepočítat řidiče)
        </Modal.Body>
      </Modal>
    </Row>
  )
}

export default FormEnrollTransport;