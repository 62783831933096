import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {createUser, getFieldOptions, getUsersToCreate} from "../../dao";
import React, {useEffect, useState} from "react";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {AccountTypeOtherOptions} from "../../helpers/Constants";
import PageContainer from "../../layout/PageContainer";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageHeading from "../../layout/PageHeading";
import Modal from "react-bootstrap/Modal";
import {useNavigate} from "react-router-dom";
import ModalContentAccount from "../../components/parts/ModalContentAccount";
import ModalContentDict from "../../components/parts/ModalContentDict";

const AddUser = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [fieldOptions, setFieldOptions] = useState({roles: {}, memberships: {}, groups: {}});
  const [tab, setTab] = useState(0);
  const [usersToCreate, setUsersToCreate] = useState([]);
  const [userToCreate, setUserToCreate] = useState(null);
  const [createdUserId, setCreatedUserId] = useState(null);
  const [generalHelp, setGeneralHelp] = useState(false);
  const [groupHelp, setGroupHelp] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [accountType, setAccountType] = useState("F");
  const [role, setRole] = useState("");
  const [membership, setMembership] = useState("");
  const [group, setGroup] = useState("");
  const [birthdate, setBirthdate] = useState(undefined);

  const [username, setUsername] = useState("");
  const [mail1, setMail1] = useState("");
  const [mail2, setMail2] = useState("");
  const [phone, setPhone] = useState("");
  const [siCards, setSiCards] = useState("");

  useEffect(() => {
    getFieldOptions()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return {roles: {}, memberships: {}, groups: {}}
        }
        return response.json();
      })
      .then((response) => {
        response.memberships = {'': '--', ...response.memberships}
        response.groups = {'': '--', ...response.groups}
        if (response.roles)
          setRole(Object.keys(response.roles)[0]);
        setFieldOptions(response);
      })
    getUsersToCreate()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return []
        }
        return response.json()
      })
      .then((response) => {
        setUsersToCreate(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    const formData = {
      "reg_number": registrationNumber,
      "username": username,
      "mail1": mail1,
      "mail2": mail2,
      "phone": phone,
      "si_cards": siCards,
      "name": name,
      "surname": surname,
      "account": accountType,
      "role": role,
      "membership": membership,
      "group": group,
      "birthdate": birthdate
    }

    createUser(formData, "manual")
      .then(response => {
        if(response.ok) {
          response.json().then(
            (result) => {
              setCreatedUserId(result.id);
              setSuccess(response.status === 202 ? "Uživatel vytvořen s korekcí" : "Uživatel vytvořen");
            });
        } else
          setError(handleErrorSubmit(response, "Nezdařilo se vytvořit", "Uživatelské jméno"));
        setProcessing(false);
      })
  };

  const handleUserCreate = () => {
    setProcessing(true);
    createUser(userToCreate, "oris")
      .then(response => {
        if(response.ok) {
          response.json().then(
            (result) => {
              setCreatedUserId(result.id);
              setSuccess("Uživatel vytvořen");
            });
        } else
          setError(handleErrorSubmit(response, "Nezdařilo se vytvořit", "Uživatelské jméno"));
        setUserToCreate(null);
        setProcessing(false);
      })
  }

  if (loading)
    return <Loader/>;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Přidat uživatele');
  return (
    <PageContainer>
      <PageHeading heading={"Přidat uživatele"} link={"/admin/"} label={"Administrace"}/>
      <Card className="mb-3" style={{minHeight: "350px"}}>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="#oris">
            <Nav.Item>
              <Nav.Link href="#oris" active={tab === 0} onClick={() => setTab(0)}>ORIS</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#rucne" active={tab === 1} onClick={() => setTab(1)}>Ručně</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        {SelectTab()}
      </Card>
      <Modal show={userToCreate != null} onHide={() => setUserToCreate(null)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Vytvořit účet uživateli {userToCreate?.RegNum}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Účet uživateli <b>{userToCreate?.RegNum} – {userToCreate?.LastName} {userToCreate?.FirstName}</b> bude vytvořen dle informací z Orisu.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setUserToCreate(null)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleUserCreate}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={generalHelp} onHide={()=>setGeneralHelp(false)}>
        <ModalContentAccount options={fieldOptions} />
      </Modal>
      <Modal show={groupHelp} onHide={()=>setGroupHelp(false)}>
        <ModalContentDict options={fieldOptions.groups} />
      </Modal>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=> {
        setSuccess("");
        navigate("/ucet/" + createdUserId + "/nastaveni/");
      }}/>
    </PageContainer>
  );

  function SelectTab () {
    switch (tab) {
      case 1:
        return <>{AddManual()}</>;
      default:
        return <>{AddOris()}</>;
    }
  }

  function AddOris () {
    const UserRow = ({element}) => {
      return <tr>
        <td>{element.RegNum}</td>
        <td>{element.LastName} {element.FirstName}</td>
        <td>{element.PersNum}</td>
        <td className="td-w-icon"><FontAwesomeIcon icon={faUserPlus} className="link-like" onClick={() => setUserToCreate(element)}/></td>
      </tr>
    }

    const rows = [];
    for (const i in usersToCreate.data)
      rows.push(<UserRow element={usersToCreate.data[i]} key={i}/>);

    if (rows.length === 0) {
      return (
        <Card.Body>
          <h4>Členové bez účtu</h4>
          <br/>
          <i>Všichni členové klubu s aktivní registrací v Orisu již mají účet.</i>
        </Card.Body>
      )
    }
    return (
      <Card.Body>
        <h4>Členové bez účtu</h4>
        <p className="mb-0">Určeno pro účty typu A a B, nově založené účty budou nastaveny takto:</p>
        <ul>
          <li>Typ – A pokud může přihlašovat sebe, B jinak <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/></li>
          <li>Role – T pokud může přihlašovat ostatní, U jinak <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/></li>
          <li>Členství – R všichni <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/></li>
        </ul>
        <Table striped bordered hover responsive>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </Card.Body>
    )
  }

  function AddManual () {
    return (
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={4} sm={0}/>
            <Col>
              <FormSelectDict label={"Typ účtu"} value={accountType} setValue={setAccountType} options={AccountTypeOtherOptions} controlId={"account"}
                              help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
              <p><i>Pro účty typu A a B využij funkci "Přidat uživatele z Orisu"</i></p>
            </Col>
            <Col md={4} sm={0}/>
          </Row>
          <Row>
            <Col>
              <FormField label={"Jméno"} value={name} setValue={setName} valueLimit={64} digits={"none"} isInvalid={name === ""} controlId={"name"} />
            </Col>
            <Col md>
              <FormField label={"Příjmení"} value={surname} setValue={setSurname} valueLimit={64} digits={"none"} isInvalid={surname === ""} controlId={"surname"} />
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormField label={"Uživatelské jméno"} value={username} setValue={setUsername} valueLimit={150} digits={"none"} controlId={"username"}/>
            </Col>
            <Col md>
              <FormField label={"Registrační číslo"} value={registrationNumber} setValue={setRegistrationNumber} valueLimit={7} digits={"none"} controlId={"registration"} />
            </Col>
            <Col md>
              <FormField label={"Datum narození"} type={"date"} value={birthdate} setValue={setBirthdate} controlId={"birthdate"}/>
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormSelectDict label={"Role"} value={role} setValue={setRole} options={fieldOptions.roles} controlId={"role"}
                              help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
            </Col>
            <Col md>
              <FormSelectDict label={"Členství"} value={membership} setValue={setMembership} options={fieldOptions.memberships} controlId={"membership"}
                              help={<FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/>}/>
            </Col>
            <Col md>
              <FormSelectDict label={"Skupina"} value={group} setValue={setGroup} options={fieldOptions.groups} controlId={"group"} />
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormField label={"Email 1"} type={"email"} value={mail1} setValue={setMail1} valueLimit={64} digits={"none"} controlId={"email1"} />
            </Col>
            <Col md>
              <FormField label={"Email 2"} type={"email"} value={mail2} setValue={setMail2} valueLimit={64} digits={"none"} controlId={"email2"} />
            </Col>
            <Col md>
              <FormField label={"Telefon"} value={phone} setValue={setPhone} valueLimit={16} digits={"none"} controlId={"phone"} />
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormField label={"Čipy (oddělené čárkou)"} value={siCards} setValue={setSiCards} controlId={"si"}/>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12 text-end">
              <Button variant="primary" type="submit" disabled={name === "" || surname === "" || processing}>Přidat</Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    )
  }
};

export default AddUser;