import Row from "react-bootstrap/Row";
import {isMobileDevice} from "../../helpers/Device";
import Col from "react-bootstrap/Col";
import AutoFormat from "../visual/AutoFormat";
import {Link} from "react-router-dom";
import {parseDate, parseDateWithoutYear} from "../../helpers/Functions";
import Card from "react-bootstrap/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendar, faCar,
  faCircleInfo,
  faClock, faHotel, faList,
  faMapLocationDot, faMaximize,
  faPencil,
  faPersonMilitaryPointing,
  faPersonRunning, faRankingStar,
  faSignsPost, faTimeline
} from "@fortawesome/free-solid-svg-icons";
import {Image} from "react-bootstrap";
import {DisciplineAllOptions, ORIS_URL} from "../../helpers/Constants";
import React from "react";
import SyncRunningIndicator from "../visual/SyncRunningIndicator";
import {selectLogoOris} from "../visual/LogosIcons";


const EventInformationCard = ({event, setShowClasses, synced=true}) => {
  const BasicDetailRow = ({icon, label, value, shrink=false, breakComma=false}) => {
    if(value != null && value !== ""){
      let value2 = ''
      if (breakComma) {
        value2 = (value.split(',').length > 1 ? value.split(',')[1] : '');
        value = value.split(',')[0]
      }
      return (
        <Row className="p-0">
          {isMobileDevice() ?
            <Col className="col-4"><p className="mb-2"><b>{label}</b></p></Col> :
            <><Col xs="auto" style={{ width: '8px'}}>{icon}</Col><Col className="col-3"><p className="mb-2"><b>{label}</b></p></Col></>
          }
          <Col className="col-8">
            <div key={label} className={shrink ? "mb-2 shrink-letters" : "mb-2"}>
              <AutoFormat text={value} key={label+"1"}/> {value2 && <><br/><AutoFormat text={value2} key={label+"2"}/></>}
            </div>
          </Col>
        </Row>
      )
    }
  }
  function connectedEvents() {
    let rows = [];
    for (const i in event?.connected) {
      const e = event?.connected[i];
      rows.push(<p className="mb-1" id={e.id}><Link to={`/akce/${e.id}`}>{parseDateWithoutYear(e.date)} - {e.title}</Link></p>)
    }
    if (rows.length === 0)
      return null;
    return rows;
  }
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Informace <SyncRunningIndicator synced={synced} /> {event?.editor && <Link to={`/akce/${event.id}/upravit`}><FontAwesomeIcon icon={faPencil}/></Link>}</Card.Title>
        {event?.type === 0 && <a href={ORIS_URL + "Zavod?id=" + event.oris_id} target='_blank' rel='noreferrer'><Image style={{zIndex: 2, position: "absolute", inset: "20px 20px auto auto"}} src={selectLogoOris(isMobileDevice())} width={isMobileDevice() ? "52px" : "150px"}/></a>}
        <BasicDetailRow icon={<FontAwesomeIcon icon={faCalendar} />} label={<>Datum:</>} value={<>{parseDate(event.date)} {event.date_to == null ? null : " – " + parseDate(event.date_to)}</>}/>
        <BasicDetailRow icon={<FontAwesomeIcon icon={faMapLocationDot} />} label={<>Místo:</>} value={event.place}/>
        {(event?.type === 0 || event?.type === 1) &&
          <>
            <BasicDetailRow icon={<FontAwesomeIcon icon={faPersonRunning} />} label={<>Disciplína:</>} value={event.discipline + " – " + DisciplineAllOptions[event.discipline]}/>
            <BasicDetailRow icon={<FontAwesomeIcon icon={faSignsPost} />} label={<>Oblast:</>} value={event.region}/>
            <BasicDetailRow icon={<FontAwesomeIcon icon={faPersonMilitaryPointing} />} label={<>Pořadatel:</>} value={event.organiser} breakComma={true}/>
            <BasicDetailRow icon={<FontAwesomeIcon icon={faClock} />} label={<>Start 00:</>} value={event.start}/>
          </>
        }
        <BasicDetailRow icon={<FontAwesomeIcon icon={faCircleInfo} />} label={<>Info:</>} value={event.info}/>
        <BasicDetailRow icon={<FontAwesomeIcon icon={faCar} />} label={<>Info doprava:</>} value={event.transport_info}/>
        {(event?.type === 0 || event?.type === 1) &&
          <BasicDetailRow icon={<FontAwesomeIcon icon={faHotel} />} label={<>Info ubytování:</>} value={event.accommodation_info}/>
        }
        {event?.type === 0 && event.in_ranking  &&
          <BasicDetailRow icon={<FontAwesomeIcon icon={faRankingStar} />} label={<>Ranking:</>} value={<Link to={`/akce/${event.id}/rank`}>předzávodní statistiky</Link>}/>
        }
        <BasicDetailRow
          icon={<FontAwesomeIcon icon={faList} />} label={event?.type === 0 ? <Link to="#" onClick={() => setShowClasses(true)} className="hide-link">Kategorie:</Link> : <>Kategorie:</>}
          value={event?.type === 0 ? <Link to="#" onClick={() => setShowClasses(true)} className="hide-link tapis-85">{event.classes} <FontAwesomeIcon icon={faMaximize} title="Rozcestník kategorií"/></Link> : event.classes} shrink={true}/>
        <BasicDetailRow
          icon={<FontAwesomeIcon icon={faTimeline} />} label={<>Spojené:</>}
          value={connectedEvents()}/>
      </Card.Body>
    </Card>
  );
}

export default EventInformationCard;