import React, {useEffect} from "react";
import {setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import Alert from "react-bootstrap/Alert";
import {labels} from "../../themeLabels";
import Table from "react-bootstrap/Table";
import NoYesIndicator from "../../components/visual/NoYesIndicator";


const IndexOfTerms = () => {
  useEffect(() => {
    const scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = 0;
  }, []);

  setBrowserTabText('Rejstřík pojmů');
  return (
    <PageContainer>
      <h1>Rejstřík pojmů</h1>
      <br/>
      <Alert variant="warning">Stránka je rozpracovaná, různé pojmy mohou chybět</Alert>
      <IndexOfTermsContent />
    </PageContainer>
  )

  function IndexOfTermsContent() {
    const TermBlock = ({title, content}) => {
      return <div>
        <h3 className="mb-3">{title}</h3>
        <div style={{marginLeft: "3%", marginRight: "3%"}}>{content}</div>
        <hr/>
      </div>
    }

    return <div>
      <TermBlock title={"Anonymní přihláška"} content={<p>Anonymní přihláška je typ přihlášky na akce Soustředění / Jiná, kterou mohou využívat lidé bez
        přihlašovacího účtu. Přístupový odkaz lze najít na stránce Administrace akce pod seznamem přihlášených, pokud jsou "Anonymní přihlášky možné".</p>}/>

      <TermBlock title={"Manuální termín"} content={<p>Manuální termín je typ termínu přihlášek určený primárně pro štafetové závody.
        Lze tak omezit moment, do kdy se uživatelé mohou hlásit na akci. Nastavení manuálního termínu je silnější než termíny z Orisu,
        tj. manuální termín bude použit, pokud je nastaven.</p>}/>

      <TermBlock title={"Předběžné přihlašování"} content={<p>Předběžné přihlašování je funkce, která umožňuje uživatelům přihlásit se v systému s předstihem
        na danou akci, a v okamžiku spuštění přihlášek server odešle nashromážděné přihlášky. Termín se nastavuje automaticky dle hodnoty "Otevření přihlášek" z
        Orisu. Předběžné přihlašování lze zakázat zaškrtnutím příslušného checkboxu v nastavení akce. <i>Pro PPčka a podobně populární akce se doporučuje
          nepovolovat předběžné přihlášky z důvodu možné nedostupnosti serveru Orisu.</i></p>}/>

      <TermBlock title={"Typ účtu"} content={<><p>Typ účtu určuje, jak se k danému účtu bude {labels.SystemShortName} chovat. Rozdíly mezi účty jsou z hlediska
        práva přihlášení se do systému, práva přihlašování se, a statusu člena klubu:</p>
        <Table bordered>
          <thead>
          <tr>
            <th></th>
            <th>člen klubu</th>
            <th>přístup {labels.SystemShortName}</th>
            <th>přihlášky Oris</th>
            <th>přihlášky {labels.SystemShortName}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>A - aktivní</td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={true} colored={true}/></td>
          </tr>
          <tr>
            <td>B - bez přístupu</td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={true} colored={true}/></td>
          </tr>
          <tr>
            <td>C - lokální</td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={true}/></td>
          </tr>
          <tr>
            <td>D - deaktivovaný</td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={false}/></td>
          </tr>
          <tr>
            <td>E - externí</td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={true} colored={true}/></td>
          </tr>
          <tr>
            <td>F - externí lokální</td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={true}/></td>
            <td><NoYesIndicator condition={false}/></td>
            <td><NoYesIndicator condition={true} colored={true}/></td>
          </tr>
          </tbody>
        </Table><ul>
          <li>člen klubu - registrován za {labels.HomeClub}, zobrazuje se v klubových přehledech, počítá se mu konto</li>
          <li>přístup {labels.SystemShortName} - pomocí svých přihlašovacích údajů může vstoupit do {labels.SystemShortName}u</li>
          <li>přihlášky Oris - pokud je politikou systému povoleno, může se uživatel přihlašovat na individuální závody do Orisu přes {labels.SystemShortName}</li>
          <li>přihlášky {labels.SystemShortName} - může se přihlašovat na klubové akce a zelení taktéž na štafety za klub</li>
        </ul><p>Pokud daný účet neprojde validací zadaných dat při vytváření, je degradován na typ C.</p></>}/>
    </div>
  }
}

export default IndexOfTerms;