import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


const CategoryEditor = ({classData, setClassData, editClasses, setEditClasses}) => {
  function updateClassData(value, categoryId, prop) {
    let tempData = JSON.parse(JSON.stringify(classData));
    for (const i in tempData) {
      let cls = tempData[i];
      if (cls.id === categoryId) {
        if (prop === "title")
          cls.title = value;
        if (prop === "note")
          cls.note = value;
        break;
      }
    }
    setClassData(tempData)
  }

  function addClass() {
    let tempData = JSON.parse(JSON.stringify(classData));
    let clsId = -1;
    for (const i in tempData) {
      let cls = tempData[i];
      if (clsId >= cls.id)
        clsId = cls.id - 1;
    }
    const cls = {
      id: clsId,
      note: "",
      title: "Kategorie" + String(tempData.length),
    };
    tempData.push(cls);
    setClassData(tempData);
  }

  function removeClass(clsId) {
    let tempData = JSON.parse(JSON.stringify(classData));
    for (const i in tempData) {
      let cls = tempData[i];
      if (clsId === cls.id)
        tempData.splice(i, 1);
    }
    setClassData(tempData);
  }

  function CategoryFields() {
    const CategoryRow = (category) => {
      return <div key={category.id}>
        <Row>
          <Col>
            <Form.Group className="mb-3" key={"categoryTitle" + category.id} controlId={"categoryTitle" + category.id}>
              <div style={{display: "flex"}}>
                <Form.Label column={true} className="me-2">Název: </Form.Label>
                <Form.Control style={{fontSize: "0.9rem"}} value={category.title} isInvalid={category.title === ""} placeholder={"(kategorie bude smazána)"}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 16)
                                  updateClassData(inputValue, category.id, "title");
                              }}/>
              </div>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Button variant="secondary" disabled={category.id >= 0} onClick={() => removeClass(category.id)}><FontAwesomeIcon icon={faTrash}/></Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" key={"categoryInfo" + category.id} controlId={"categoryInfo" + category.id}>
              <div style={{display: "flex"}}>
                <Form.Label column={true} className="me-2">Info: </Form.Label>
                <Form.Control style={{fontSize: "0.9rem"}} value={category.note} placeholder={"(volitelné)"}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 128)
                                  updateClassData(inputValue, category.id, "note");
                              }} />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <hr/>
      </div>
    }

    const rows = [];
    for (const i in classData)
      rows.push(CategoryRow(classData[i]));
    return rows;
  }

  return (
    <Modal show={editClasses} onHide={() => setEditClasses(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Editovat kategorie:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {CategoryFields()}
        </Form>
        <Row>
          <Col className="text-end">
            <Button variant="secondary" onClick={addClass}>+ Přidat</Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <i>Po dokončení úprav zavři toto okno, kategorie se uloží spolu s nastavením akce.</i>
        <Button onClick={() => setEditClasses(false)}>Zavřít</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CategoryEditor;