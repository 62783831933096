// PostEditor.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PostEditor = ({ initialContent, onSave, buttonText="Uložit", buttonDisabled=false }) => {
  const [content, setContent] = useState(initialContent || '');

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSave = () => {
    onSave(content);
  };

  return (
    <div>
      <ReactQuill
        value={content}
        onChange={handleChange}
        modules={PostEditor.modules}
        formats={PostEditor.formats}
      />
      <Row>
        <Col className="text-end">
          <Button className="mt-3" onClick={handleSave} disabled={buttonDisabled}><b>{buttonText}</b></Button>
        </Col>
      </Row>
      <p><i>Pozn.: Na webu nelze pro odsazení textu používat mezery, používej tlačítko pro odsazení řádky (vpravo vedle seznamů)!</i></p>
    </div>
  );
};

PostEditor.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}],
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    [{ 'color': [] }, { 'background': [] }],
    ['clean']
  ],
};

PostEditor.formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'indent',
  'color', 'background', 'link'
];

export default PostEditor;
