import {parseDateTime} from "../../helpers/Functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faHandHoldingDollar} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";


const OfferOrderSection = ({offers, eventId, user}) => {
  const navigate = useNavigate();

  const rows = [];
  for (const i in offers) {
    const offer = offers[i];
    const repletionCoef = Number(offer.ordered) / Number(offer.amount);
    const availableColor = repletionCoef >= 1 ? "red" : (repletionCoef >= 0.75 ? "orange" : "inherit");
    rows.push(<tr key={offer.id} className={offer.can_add ? "" : "table-success"}>
      <td>{offer.title}</td>
      <td>{offer.unit} Kč</td>
      <td><i>{parseDateTime(offer.term)}</i></td>
      <td title={"objednáno " + offer.ordered + " / celkem " + offer.amount}><b style={{color: availableColor}}>{offer.available}</b></td>
      {(offer.available > 0 && offer.can_add && offer.can_order) ? (<td align="center" title="Objednat" onClick={() => {
        navigate("/akce/"+eventId+"/nabidky",{state: {selectBuyer: user, selectOffer: offer}});
      }}><FontAwesomeIcon icon={faCartPlus} className="link-like"/></td>) : (<td align="center"><b>{offer?.quantity}</b></td>)}
    </tr>)
  }

  if (rows.length === 0)
    return <></>

  return <div>
    <hr/>
    <h4>Klubové nabídky</h4>
    <p className="mb-2"><i>Nabídky poskytované mým klubem</i></p>
    <Table striped bordered hover responsive>
      <thead>
      <tr>
        <th>Popis</th>
        <th>Cena</th>
        <th>Termín</th>
        <th>Volno</th>
        <th>Obj.</th>
      </tr>
      </thead>
      <tbody>
      {rows}
      </tbody>
    </Table>
    <Row>
      <Col className="text-end">
        <Link to={`/akce/${eventId}/nabidky`}><Button className="mb-2" variant="outline-primary"><FontAwesomeIcon icon={faHandHoldingDollar}/> Objednávky nabídek</Button></Link>
      </Col>
    </Row>
  </div>
}

export default OfferOrderSection;