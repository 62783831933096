import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Badge} from "react-bootstrap";
import {labels} from "../../themeLabels";
import React from "react";
import {Theme} from "../../helpers/Constants";


const EventClubCard = ({event}) => {
  const EventClubCardTitle = () => {
    if (Theme === "PGP") {
      return <><FontAwesomeIcon icon={faUserGroup} /> Klub</>
    }
    return <Row className="align-items-center">
      <Col xs="auto" className="mb-0 pe-1"><FontAwesomeIcon icon={faUserGroup} /> Klub </Col>
      <Col xs="auto" className="ps-0"><Link to={`/akce/${event.id}/prihlasky`}><Badge style={{fontSize: "0.9em"}} pill bg="secondary">{event.club_entries}</Badge></Link></Col>
    </Row>
  }

  const EventClubEnrolls = () => {
    if (Theme === "PGP") {
      return <Link to={`/akce/${event.id}/prihlasky`} className="keep-all"><b>{event?.startlists ? "Startovky" : "Přihlášky"} ({event.club_entries})</b></Link>
    }
    return <Link to={`/akce/${event.id}/prihlasky`} className="keep-all"><b>{event?.startlists ? "Startovky" : "Přihlášky"}</b></Link>
  }

  return(
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>
          <EventClubCardTitle />
        </Card.Title>
        <Row>
          <Col className="mb-2" xs="auto">
            <EventClubEnrolls />
          </Col>
          {(event.offers > 0 && labels.offersPage) && <Col xs="auto" className="mb-2">
            <Link to={`/akce/${event.id}/nabidky`} className="keep-all">{labels.offersPage} ({event.offers})</Link>
          </Col>}
          {event.editor &&
            <Col className="mb-2" xs="auto">
              <Link to={`/akce/${event.id}/admin`} className="keep-all">Administrace akce</Link>
            </Col> }
          <Col className="mb-2" xs="auto">
            <Link to={`/akce/${event.id}/doprava/detail`} className="keep-all">Doprava</Link>
          </Col>
          {event.editor &&
            <Col className="mb-2" xs="auto">
              <Link to={`/akce/${event.id}/export`} className="keep-all">Exporty</Link>
            </Col> }
        </Row>
      </Card.Body>
    </Card>
  );
}

export default EventClubCard;