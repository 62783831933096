import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {handleErrorSubmit, parseDate} from "../../helpers/Functions";
import FormField from "../form/FormField";
import ErrorAlert from "../overlays/ErrorAlert";
import SuccessAlert from "../overlays/SuccessAlert";
import {useNavigate} from "react-router-dom";
import {deleteEvent} from "../../dao";


const DeleteEventZone = ({event}) => {
  const [showContent, setShowContent] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  function handleRemove() {
    setShowConfirm(false);
    deleteEvent(event?.id)
      .then((response) => {
        if (response.ok) {
          setSuccess("Smazáno");
        } else {
          setError(handleErrorSubmit(response, "Nezdařilo se smazat"));
        }
      });
  }

  return (
    <div>
      <hr/>
      <div>
        <h4 className="pointing" style={{color: "red"}} onClick={() => setShowContent(!showContent)}>
          <FontAwesomeIcon icon={showContent ? faAngleUp : faAngleDown}/> Danger zone</h4>
        {showContent && <Row>
          <Col className="text-end">
            <Button variant="danger" onClick={() => setShowConfirm(true)}><FontAwesomeIcon icon={faTrash}/> Smazat akci</Button>
          </Col>
        </Row>}
      </div>
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Skutečně smazat akci?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="prevent-select">Název: <b>{event?.title}</b></p>
          <p>Datum: {parseDate(event?.date)} {event?.date_to ? " – " + parseDate(event?.date_to) : ""}</p>
          <hr/>
          <p>Spolu s akcí dojde ke smazání <b style={{color: "red"}}>všech</b> propojených entit - přihlášek, pohledávek, vozidel apod.</p>
          <p>Tuto akci <b style={{color: "red"}}>nelze vrátit zpět</b>!</p>
          <FormField label={"Pro potvrzení opiš přesný název akce"} value={confirmText} setValue={setConfirmText} controlId={"confirmText"} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>Zavřít</Button>
          <Button variant="danger" disabled={event?.title !== confirmText} onClick={handleRemove}>Smazat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=> {
        setSuccess("");
        navigate("/");
      }}/>
    </div>
  );
}

export default DeleteEventZone;