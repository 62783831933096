import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react'
import {getClubEnrollsAdmin, getEventApplyRights} from "../../dao";
import { useParams } from "react-router-dom";
import {
  faCar,
  faCheck,
  faNoteSticky,
  faPencil,
  faReceipt,
  faXmark,
  faCarTunnel,
  faCheckDouble,
  faRectangleList,
  faEye, faUserPen
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import SelectUserModal from "../../components/parts/SelectUserModal";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import PageContainer from "../../layout/PageContainer";
import PageHeading from "../../layout/PageHeading";
import {HomeClub, MobileWidthLimitSM} from "../../helpers/Constants";
import NoYesIndicator from "../../components/visual/NoYesIndicator";
import FormCheckbox from "../../components/form/FormCheckbox";
import {labels} from "../../themeLabels";
import WarningCard from "../../components/overlays/WarningCard";

const ClubEventAdmin = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState({});
  const [event, setEvent] = useState([]);
  const [rightData, setRightData] = useState({count:-1, rights: []});
  const [showClubOnly, setShowClubOnly] = useState(true);
  const [selectUsersShow, setSelectUsersShow] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= MobileWidthLimitSM);

  useEffect(() => {
    const handleOrientationChange = () => {setIsMobile(window.innerWidth <= MobileWidthLimitSM)};
    window.addEventListener('resize', handleOrientationChange);
    return () => {window.removeEventListener('resize', handleOrientationChange)};
  }, []);

  useEffect(() => {
    setLoading(true);
    getClubEnrollsAdmin(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
        })
      .then((response) => {
        setData(response);
        setEvent(response?.event);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    getEventApplyRights(eventId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {count:-1, rights: []}
        }
        return response.json()
      })
      .then((response) => {
        setRightData(response);
      })
    }, [eventId]);

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Admin | ' + event?.title);
  return (
    <PageContainer>
      <PageHeading heading={"Administrace: " + event?.title} id={eventId} />
      <ControlCard/>
      <Row className="mb-2">
        <Col>
          <FormCheckbox type="switch" label={"Pouze " + HomeClub} value={showClubOnly} setValue={setShowClubOnly} controlId={"showClubOnly"}/>
        </Col>
      </Row>
      <ClubEnrollsTable enrolls={data.entries}/>
      {(event?.type === 2 && event?.allow_anonym) && <>
        <p className="mb-1">Odkaz na anonymní přihlášku: <a href={`/anonym/akce/${eventId}/prihlaska`} target="_blank" rel="noreferrer">{"/anonym/akce/" + eventId + "/prihlaska"}</a></p>
        <i>Anonymní přihlášky slouží pro nečleny klubu (nemají přístup do {labels.SystemShortName}u).</i>
      </>}
      <SelectUserModal
        id={eventId}
        show={selectUsersShow}
        onHide={() => {setSelectUsersShow(false)}}
        title="Vybrat uživatele pro přihlášení"
        people={rightData.rights}
        loading={rightData.count === -1}
      />
    </PageContainer>);

  function ControlCard() {
    const TernaryIcon = ({subject}) => {
      if (subject === 1)
        return <FontAwesomeIcon icon={faCheckDouble} />;
      if (subject === 0)
        return <FontAwesomeIcon icon={faCheck} />;
      return <FontAwesomeIcon icon={faXmark} />;
    }

    const LeftAlignedItems = () => {
      return (<Row className="align-items-center">
        <Col xs="auto" className="mb-2">
          <Button href={`/akce/${eventId}/upravit`}><FontAwesomeIcon icon={faPencil}/> Upravit akci</Button>
        </Col>
        {event?.type !== 0 && <Col xs="auto" className="mb-2">
          <Button variant="outline-secondary" href={`/akce/${eventId}/whitelist`}><FontAwesomeIcon icon={faEye}/> Whitelist</Button>
        </Col>}
        <Col xs="auto" className="mb-2">
          <Button variant="secondary" href={`/akce/${eventId}/editori`}><FontAwesomeIcon icon={faUserPen}/> Správa editorů ({data?.editor_count})</Button>
        </Col>
        <Col className="mb-2">
          <Button variant="link" onClick={() => setSelectUsersShow(true)}>{event.type === 0 && !event?.is_relay ? labels.entryOthersRemote : labels.entryOthersLocal}</Button>
        </Col>
      </Row>)
    }

    const RightAlignedItems = () => {
      return (<Row className="align-items-center justify-content-end">
        <Col xs="auto" className="mb-2"><Link to={`/akce/${eventId}/doprava/detail`}>Doprava</Link></Col>
        <Col xs="auto" className="mb-2">U: <TernaryIcon subject={event?.accommodation} /> | S: <TernaryIcon subject={event?.dining} /> | D: <TernaryIcon subject={event?.transport} /> </Col>
        <Col xs="auto" className="mb-2"><Link to={`/akce/${eventId}/pohledavky`}><Button><FontAwesomeIcon icon={faReceipt}/> Pohledávky</Button></Link></Col>
      </Row>)
    }

    return (
      <Card className="mb-3 align-content-stretch align-items-stretch">
        <Card.Body>
          <Row className="align-items-center">
            <Col lg={7}><LeftAlignedItems/></Col>
            {isMobile && <Col xs={12}><hr/></Col>}
            <Col><RightAlignedItems/></Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function ClubEnrollsTable(props){
    if(props.enrolls?.length > 0){
      return (
        <Table striped bordered hover responsive>
          <thead>
          <tr>
            {event.type !== 2 && (event.startlists ?
              (isMobile ? <th>Start</th> : (<>
                <th>Start</th>
                <th>Kategorie</th>
              </>)) :
              (isMobile ? <th>Kat.</th> : <th>Kategorie</th>))
            }
            {isMobile ? <th>Osoba</th> : (<>
              <th>Registrace</th>
              <th>Jméno</th>
              <th>Čip</th>
            </>)}
            {event.type !== 2 ?
              <th title="Ubytování" className="align-center">U ({data?.accommodation_count})</th> :
              <th>{data?.highlighted}</th>
            }
            {event?.dining >= 0 && <th title="Stravování" className="align-center">S ({data?.dining_count})</th>}
            <th title="Doprava" className="align-center">D ({data?.transport_count})</th>
            <th title="Poznámky" className="align-center">P.</th>
            <th className="td-w-icon"><Link to={`/akce/${eventId}/prihlasky`}><FontAwesomeIcon icon={faRectangleList}/></Link></th>
          </tr>
          </thead>
          <tbody>
          <TableContent enrolls = {props.enrolls}/>
          </tbody>
        </Table>
      );
    }
    return <WarningCard text="Žádné přihlášky" />;

    function TableContent(props){
      const TableRow = ({element}) => {
        const [showDetail, setShowDetail] = useState(false);

        function changeState() {
          setShowDetail(current => !current);
        }

        const TransportIcon = () => {
          switch (element.transport) {
            case 1:
              return <FontAwesomeIcon icon={faCheck} title="ano bez auta"/>;
            case 2:
              return <><FontAwesomeIcon icon={faCarTunnel} color="gray" title="ano s možností auta"/> {element.car_capacity}</>;
            case 3:
              return <><FontAwesomeIcon icon={faCar} title="ano s autem"/> {element.car_capacity}</>;
            default:
              return <FontAwesomeIcon icon={faXmark} />;
          }
        }

        const url = "/akce/"+eventId+"/prihlaska/"+element.id;
        return(<>
          <tr>
            {event.type !== 2 && (event.startlists ?
              (isMobile ?
                <td>{element.start_time}<br/><i>{element.class_name}</i></td> :
                (<>
                  <td>{element.start_time}</td>
                  <td>{element.class_name}</td>
                </>)) :
              (<td>{element.class_name}</td>))
            }
            {isMobile ?
              (<td><Link to={`/ucet/${element.id}/zavody`} className="hide-link">{element.full_name}</Link><br/><i>{element.reg_number}</i></td>) :
              (<>
                <td>{element.reg_number}</td>
                <td><Link to={`/ucet/${element.id}/zavody`} className="hide-link">{element.full_name}</Link></td>
                <td>{element.si}</td>
              </>)
            }
            {event.type !== 2 ?
              <td align="center"><NoYesIndicator condition={element.accommodation === 1} allBlack={true}/></td> :
              <td>{element?.highlighted}</td>
            }
            {event?.dining >= 0 && <td align="center"><NoYesIndicator condition={element?.dining === 1} allBlack={true}/></td>}
            <td align="center"><TransportIcon /></td>
            {(element.note === "" && element.start_request === "" && element.club_note === "") ? <td></td> :
              <td align={"center"}><FontAwesomeIcon icon={faNoteSticky} onClick={changeState} size="lg"/></td>}
            <td className="td-w-icon">
              {element.can_edit && <Link to={url}><FontAwesomeIcon icon={faPencil} size="lg"/></Link>}
            </td>
          </tr>
          {showDetail && (<tr><td colSpan={isMobile ? 6 : (event.startlists ? 9 : 8)}>
            {element.note !== "" && <><i>Pozn.{event.type === 0 && " ORIS"}:</i> {element.note}<br/></>}
            {element.start_request !== "" && <><i>Start. pož.:</i> {element.start_request}<br/></>}
            {element.club_note !== "" && <><i>Pozn. {HomeClub}:</i> {element.club_note}<br/></>}
          </td></tr>)}
        </>)
      }
      if(props.enrolls != null){
        const rows = [];
        for(const element in props.enrolls) {
          if (showClubOnly) {
            if (props.enrolls[element].from_home_club)
              rows.push(<TableRow element={props.enrolls[element]} key={element}/>);
          }
          else
            rows.push(<TableRow element={props.enrolls[element]} key={element}/>);
        }
        return(rows);
      }
    }
  }
};

export default ClubEventAdmin;