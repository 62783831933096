function SelectOptionsArray(props){
  const res = [];
  if(props.options != null){
    props.options.forEach(option => {
      res.push(<option key={option} value={option}>{option}</option>);
    });
  }
  return res;
}

function SelectOptionsDict({options}){
  const res = [];
  for (const i in options) {
    res.push(<option key={i} value={i}>{options[i]}</option>);
  }
  return res;
}

export {SelectOptionsArray, SelectOptionsDict};